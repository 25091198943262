<script>
import { db } from "@/@core/firebase"
import { ref } from "@vue/composition-api"
import {
  BCard,
  BCardHeader,
  BCardBody,
  BFormInput,
  BFormGroup,
  BButton,
} from "bootstrap-vue"
import { getDoc, doc, updateDoc } from "firebase/firestore"
export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BFormGroup,
    BButton,
  },
  setup() {
    const session = ref("")
    ;(async () => {
      const crashConfig = await getDoc(doc(db, "apostaGanha/crash"))
      session.value = crashConfig.data().session
    })()

    const save = () => {
      updateDoc(doc(db, "apostaGanha/crash"), {
        session: session.value,
      })
    }

    return {
      session,
      save,
    }
  },
}
</script>
<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Configurações</h5>
    </b-card-header>
    <b-card-body>
      <b-form-group>
        <label for="name">Sessão do Aviator:</label>
        <b-form-input v-model="session"></b-form-input>
      </b-form-group>
      <b-button variant="primary" @click="save">Salvar </b-button>
    </b-card-body>
  </b-card>
</template>
